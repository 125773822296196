export enum APPLICATION_SOURCE_IDS {
  Lkk = 1,
  Api = 2,
  Lkf = 3,
};
export const APPLICATION_SOURCES: {[key in typeof APPLICATION_SOURCE_IDS[keyof typeof APPLICATION_SOURCE_IDS]]: string} = {
  [APPLICATION_SOURCE_IDS.Lkk]: 'Личный кабинет клиента',
  [APPLICATION_SOURCE_IDS.Api]: 'Партнерский API',
  [APPLICATION_SOURCE_IDS.Lkf]: 'Кабинет филиала',
};

export enum CERT_ISSUER_TYPE_IDS {
  Uc = 0,
  Fts = 1,
  Lissi = 2,
};
export const CERT_ISSUER_TYPES: {[key in typeof CERT_ISSUER_TYPE_IDS[keyof typeof CERT_ISSUER_TYPE_IDS]]: string} = {
  [CERT_ISSUER_TYPE_IDS.Uc]: 'АО "АЦ"',
  [CERT_ISSUER_TYPE_IDS.Fts]: 'ФНС',
  [CERT_ISSUER_TYPE_IDS.Lissi]: 'Лисси',
};

export enum CERT_SUBJECT_TYPE_IDS {
  Person = 1,
  Entrepreneur = 2,
  Legal = 3,
  Employee = 4,
};
export const CERT_SUBJECT_TYPES: {[key in typeof CERT_SUBJECT_TYPE_IDS[keyof typeof CERT_SUBJECT_TYPE_IDS]]: string} = {
  [CERT_SUBJECT_TYPE_IDS.Person]: 'Физическое лицо',
  [CERT_SUBJECT_TYPE_IDS.Entrepreneur]: 'Индивидуальный предприниматель',
  [CERT_SUBJECT_TYPE_IDS.Legal]: 'Юридическое лицо',
  [CERT_SUBJECT_TYPE_IDS.Employee]: 'Сотрудник ЮЛ',
};

export enum STATUS_IDS {
  New = 1,
  DocumentsReady = 2,
  Reactivated = 3,
  Processing = 10,
  Success = 11,
  Rejected = 12,
  Archive = 20,
};
export const STATUSES: {[key in typeof STATUS_IDS[keyof typeof STATUS_IDS]]: {name: string; color: string}} = {
  [STATUS_IDS.New]: {
    name: 'Новая, ожидает документы',
    color: '#AAAAAA'
  },
  [STATUS_IDS.DocumentsReady]: {
    name: 'Подтверждено, ожидает обработки',
    color: '#CCCC22'
  },
  [STATUS_IDS.Reactivated]: {
    name: 'Перезапущена. Ожидает обработки',
    color: '#CCCC22'
  },
  [STATUS_IDS.Processing]: {
    name: 'В работе',
    color: '#CCCC22'
  },
  [STATUS_IDS.Success]: {
    name: 'Отозван',
    color: '#22CC22'
  },
  [STATUS_IDS.Rejected]: {
    name: 'Отказ',
    color: '#CC2222'
  },
  [STATUS_IDS.Archive]: {
    name: 'Архив',
    color: '#22CC22',
  },
};

export enum REVOKE_IDENTIFICATION_TYPE_IDS {
  Direct = 1,
  Signature = 2,
};
export const REVOKE_IDENTIFICATION_TYPES: {[key in typeof REVOKE_IDENTIFICATION_TYPE_IDS[keyof typeof REVOKE_IDENTIFICATION_TYPE_IDS]]: string} = {
  [REVOKE_IDENTIFICATION_TYPE_IDS.Direct]: 'Лично',
  [REVOKE_IDENTIFICATION_TYPE_IDS.Signature]: 'Подписание действующей ЭП',
};

export enum DOCUMENT_TYPE_IDS {
  RevokeApplication = 0,
  RevokeApplicationScan = 1,
  RevokeProcuration = 2,
  RevokeApplicantSignature = 3,
  RevokeFilialSignature = 4,
};
export const DOCUMENT_TYPES: {[key in typeof DOCUMENT_TYPE_IDS[keyof typeof DOCUMENT_TYPE_IDS]]: string} = {
  [DOCUMENT_TYPE_IDS.RevokeApplication]: 'Заявление на отзыв',
  [DOCUMENT_TYPE_IDS.RevokeApplicationScan]: 'Скан заявления',
  [DOCUMENT_TYPE_IDS.RevokeProcuration]: 'Доверенность',
  [DOCUMENT_TYPE_IDS.RevokeApplicantSignature]: 'Подпись заявителя',
  [DOCUMENT_TYPE_IDS.RevokeFilialSignature]: 'Подпись филиала',
};

export enum REVOKE_REASON_TYPE_IDS {
  ReleaseNew = 1,
  TokenLost = 2,
  TokenBroken = 3,
  TokenStolen = 4,
  NotNeeded = 5,
};
export const REVOKE_REASON_TYPES: {[key in typeof REVOKE_REASON_TYPE_IDS[keyof typeof REVOKE_REASON_TYPE_IDS]]: string} = {
  [REVOKE_REASON_TYPE_IDS.ReleaseNew]: 'Выпуск сертификата',
  [REVOKE_REASON_TYPE_IDS.TokenLost]: 'Утеря токена',
  [REVOKE_REASON_TYPE_IDS.TokenBroken]: 'Неисправный токен',
  [REVOKE_REASON_TYPE_IDS.TokenStolen]: 'Подозрение на незаконное использование третьими лицами',
  [REVOKE_REASON_TYPE_IDS.NotNeeded]: 'Более не нужен',
};

export enum FORM_SECTIONS {
  Application = 'Данные заявки',
  Organization = 'Данные организации',
  Applicant = 'Данные заявителя',
};
