import {
  STATUSES,
  STATUS_IDS,
  CERT_SUBJECT_TYPES,
  CERT_SUBJECT_TYPE_IDS
} from '@/constants/revoke.constants';

export const ALL_FILTER_CONTROLS = {
  fl: {
    name: "fl",
    type: "checkbox",
    label: "Физическое лицо",
    value: false
  },
  ip: {
    name: "ip",
    type: "checkbox",
    label: "Индивидуальный предприниматель",
    value: false
  },
  ur: {
    name: "ur",
    type: "checkbox",
    label: "Юридическое лицо",
    value: false
  },
  statusSendDoc: {
    name: "statusSendDoc",
    type: "checkbox",
    label: "Отправка документов",
    value: false
  },
  statusGenReq: {
    name: "statusGenReq",
    type: "checkbox",
    label: "Генерация запроса",
    value: false
  },
  statusRelease: {
    name: "statusRelease",
    type: "checkbox",
    label: "Выпуск",
    value: false
  },
  statusDone: {
    name: "statusDone",
    type: "checkbox",
    label: "Готово",
    value: false
  },
  statusFail: {
    name: "statusFail",
    type: "checkbox",
    label: "Отказ",
    value: false
  },
  statusPayed: {
    name: "statusPayed",
    type: "checkbox",
    label: "Оплачено",
    value: false
  },
  statusWaitingOriginals: {
    name: "statusWaitingOriginals",
    type: "checkbox",
    label: "Ожидание оригиналов",
    value: false
  },
  statusOriginalsIncorrect: {
    name: "statusOriginalsIncorrect",
    type: "checkbox",
    label: "Оригиналы некорректны",
    value: false
  },
  statusClosed: {
    name: "statusClosed",
    type: "checkbox",
    label: "Завершено",
    value: false
  },
  statusNew: {
    name: "statusNew",
    type: "checkbox",
    label: "Новая предзаявка",
    value: false
  },
  statusAppFormed: {
    name: "statusAppFormed",
    type: "checkbox",
    label: "Заявка сформирована",
    value: false
  },
  statusInWork: {
    name: "statusInWork",
    type: "checkbox",
    label: "В работе",
    value: false
  },
  statusWaitForConfirm: {
    name: "statusWaitForConfirm",
    type: "checkbox",
    label: "Ожидание подтверждения",
    value: false
  },
  statusWaitForDocs: {
    name: "statusWaitForDocs",
    type: "checkbox",
    label: "Ожидание документов",
    value: false
  },
  statusClientRefused: {
    name: "statusClientRefused",
    type: "checkbox",
    label: "Клиент отказался",
    value: false
  },
  statusOutOfReach: {
    name: "statusOutOfReach",
    type: "checkbox",
    label: "Не выходит на связь",
    value: false
  },
  statusOtherReason: {
    name: "statusOtherReason",
    type: "checkbox",
    label: "Прочее",
    value: false
  },
  statusDuplicate: {
    name: "statusDuplicate",
    type: "checkbox",
    label: "Повтор",
    value: false
  },
  statusIdentificationCreated: {
    name: "statusIdentificationCreated",
    type: "checkbox",
    label: "Новая",
    value: false
  },
  statusIdentificationConfirmed: {
    name: "statusIdentificationConfirmed",
    type: "checkbox",
    label: "Подтверждена",
    value: false
  },
  statusIdentificationCanceled: {
    name: "statusIdentificationCanceled",
    type: "checkbox",
    label: "Отклонена",
    value: false
  },
  statusOnModeration: {
    name: "statusOnModeration",
    type: "checkbox",
    label: "На модерации",
    value: false
  },
  date: {
    value: ""
  },
  select: {
    type: "select",
    value: 0,
    options: []
  }
};

export const REVOKE_APPLICANTS = {
  subjectPerson: {
    name: "subjectPerson",
    type: "checkbox",
    label: CERT_SUBJECT_TYPES[CERT_SUBJECT_TYPE_IDS.Person],
    value: false
  },
  subjectEntrepreneur: {
    name: "subjectEntrepreneur",
    type: "checkbox",
    label: CERT_SUBJECT_TYPES[CERT_SUBJECT_TYPE_IDS.Entrepreneur],
    value: false
  },
  subjectLegal: {
    name: "subjectLegal",
    type: "checkbox",
    label: CERT_SUBJECT_TYPES[CERT_SUBJECT_TYPE_IDS.Legal],
    value: false
  },
  subjectEmployee: {
    name: "subjectEmployee",
    type: "checkbox",
    label: CERT_SUBJECT_TYPES[CERT_SUBJECT_TYPE_IDS.Employee],
    value: false
  },
};

export const REVOKE_STATUSES = {
  statusNew: {
    name: "statusNew",
    type: "checkbox",
    label: STATUSES[STATUS_IDS.New].name,
    value: false
  },
  statusInProcess: {
    name: "statusInProcess",
    type: "checkbox",
    label: STATUSES[STATUS_IDS.Processing].name,
    value: false
  },
  statusDocumentsReady: {
    name: "statusDocumentsReady",
    type: "checkbox",
    label: STATUSES[STATUS_IDS.DocumentsReady].name,
    value: false
  },
  statusSuccess: {
    name: "statusSuccess",
    type: "checkbox",
    label: STATUSES[STATUS_IDS.Success].name,
    value: false
  },
};
