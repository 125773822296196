import { GetterTree } from "vuex";
import { isEmpty } from "lodash";

import { IProductsState, IProduct } from "./products.interfaces";
import { TARIFF_BINDINGS, PRODUCT_KINDS, PRODUCT_BINDS } from "./products.constants";
import { RootState } from "@/store/root.interface";

import { application } from "../application";

export const getters: GetterTree<IProductsState, RootState> = {
  getSelectedNames(store) {
    const typeEntre = (application as any).state.formControls.typeEntre.value;

    let names="";
    store.productsSelected.forEach((id) => {
      const prod: IProduct | undefined = store.products.find(
        product => product.id === id
      );
      if(prod){
        if(!isEmpty(prod.name) && prod.price[typeEntre] as number > 0){
          names += `${prod.name},`;
        }
      }
    });
    return names.substr(0,names.length-1);
  },

  getProductsSelected(state) {
    const products = state.products.filter((p: IProduct) => {
      return state.productsSelected.includes(p.id);
    })
    return products;
  },

  getProductsSelectedWithEDM(state, getters) {
    return getters.getProductsSelected.filter((p: IProduct) => {
      return p.hasOwnProperty("bind") && p.bind == TARIFF_BINDINGS.EDMIntegration.id;
    });
  },

  getHasProductsWithEDM(state, getters) {
    return !!getters.getProductsSelectedWithEDM.length;
  },

  getHasTariffFNS(state, getters) {
    return !!getters.getProductsSelected.filter((p: IProduct) => p.productKind === PRODUCT_KINDS.FNS.id).length;
  },

  getHasTariffInsurance(state, getters) {
    return getters.getProductsSelected.some((p: IProduct) => PRODUCT_BINDS.Insurance.includes(p.bind));
  },

  getHasTariffKSKPEP(state, getters) {
    return !!getters.getProductsSelected.filter((p: IProduct) => p.productKind === PRODUCT_KINDS.KSKPEP.id).length;
  },
};
