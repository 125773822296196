export const TARIFF_BINDINGS = {
  None: {
    id: 0,
    title: "Не задано"
  },
  MoeDeloInternet: {
    id: 1,
    title: "Моё дело Интернет"
  },
  MoeDeloOutsourcing: {
    id: 2,
    title: "Моё дело Аутсорсинг"
  },
  Fabricant: {
    id: 3,
    title: "Фабрикант"
  },
  EDMIntegration: {
    id: 4,
    title: "ЭДО интеграция"
  },

};

export const FNS_TARIFF_IDS = {
  Test1: [205,224,254,280,302,322,358,366,398,477,484,511,517,526,586,728,735,741,750,788,805,822,827,847,1046,1107],
  Production: []
};

export const PRODUCT_KINDS = {
  KSKPEP: {
    id: 1
  },
  FNS: {
    id: 32
  }
};

export const test: any = [
  {
    id: 3,
    name: "КСКПЭП",
    price: { fl: 499, ip: 499, ur: 499 },
    required: true,
    oids: [
      "1.3.6.1.5.5.7.3.2",
      "1.3.6.1.5.5.7.3.4",
      "1.3.6.1.4.1.311.20.2.2",
      "1.2.643.2.2.34.25"
    ]
  },
  {
    id: 4,
    name: "ЭТП, входящие в состав АЭТП",
    price: { fl: 2499, ip: 2502, ur: 2503 },
    required: false,
    oids: [
      "1.3.6.1.5.5.7.3.2",
      "1.3.6.1.5.5.7.3.4",
      "1.3.6.1.4.1.311.20.2.2",
      "1.2.643.2.2.34.25"
    ]
  },
  {
    id: 55,
    name: "Росреестр тест",
    price: { fl: 500, ip: 1818, ur: 1858 },
    required: false,
    subitemsType: "single",
    subitems: [
      {
        id: 115,
        name: "Судебный пристав-исполнитель",
        oids: ["1.2.643.5.1.24.2.9"]
      },
      {
        id: 118,
        name:
          "Руководитель (заместитель руководителя) федерального органа исполнительной власти",
        oids: ["1.2.643.5.1.24.2.20", "1.2.643.5.1.24.2.30", "1.2.643.100.2.2"]
      },
      {
        id: 145,
        name:
          "Руководитель правоохранительного органа или иное уполномоченное лицо в соответствии с законодательством РФ должностное лицо данного органа",
        oids: ["1.2.643.5.1.24.2.15", "1.2.643.100.2.2"]
      }
    ]
  },
  {
    id: 5,
    name: "ЭТП, входящие в состав АЭТП",
    price: { fl: 2499, ip: 2502, ur: 2503 },
    required: false,
    oids: [
      "1.2.643.3.58.2.1.1",
      "1.2.643.3.8.100.1.42",
      "1.2.643.6.3",
      "1.2.643.6.3.2",
      "1.2.643.6.41"
    ]
  },
  {
    id: 45,
    name: "Росреестр",
    price: { fl: 500, ip: 1818, ur: 1858 },
    required: false,
    subitemsType: "multiple",
    subitems: [
      {
        id: 110,
        name: "Судебный пристав-исполнитель",
        oids: ["1.2.643.5.1.24.2.9"]
      },
      {
        id: 111,
        name:
          "Руководитель (заместитель руководителя) федерального органа исполнительной власти",
        oids: ["1.2.643.5.1.24.2.20", "1.2.643.5.1.24.2.30", "1.2.643.100.2.2"]
      },
      {
        id: 112,
        name:
          "Руководитель правоохранительного органа или иное уполномоченное лицо в соответствии с законодательством РФ должностное лицо данного органа",
        oids: ["1.2.643.5.1.24.2.15", "1.2.643.100.2.2"]
      }
    ]
  }
];

export const PRODUCT_BINDS = {
  Insurance: [6, 7, 8],
};
