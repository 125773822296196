import { GetterTree } from 'vuex';

import { RootState } from '@/store/root.interface';
import { ICertificateListState, IListItem } from './certificate-list.types';

export const getters: GetterTree<ICertificateListState, RootState> = {
  getCertificateList(state) {
    return state.list.map((item: IListItem) => {
      const row = [];
      row.push({link: item.applicationId ? `№ ${item.applicationId}` : '', href: `/application/${item.applicationId}`});
      row.push({
        value: item.status ?? '',
        controls: {
          refreshButton: {
            style: {position: 'absolute', top: '14px', left: '120px'},
            tooltip: 'Перепроверить статус',
            values: {appId: item.applicationId, serialNumber: item.serialNumber, thumbprint: item.thumbprint}
          }
        }
      });
      row.push(item.applicantFullName ?? '');
      row.push(item.organizationName ?? '');
      row.push(item.innLegal ?? '');
      row.push(item.innPerson ?? '');
      row.push(item.dateValidFrom ?? '');
      row.push(item.dateValidTo ?? '');
      return row;
    });
  },
};
